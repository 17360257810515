import React, { Component } from 'react'
import { Container } from '@material-ui/core'
import Head from 'react-helmet'

import Features from '../Home/Features'
import Layout from '../components/Layout'

export class FeaturesPage extends Component {
  render() {
    return (
      <Layout>
        <Head>
          <title>Smartest Way to Manage Your Finances</title>
        </Head>
        <Features />
      </Layout>
    )
  }
}

export default FeaturesPage
